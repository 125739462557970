import { AbilityBuilder, Ability } from '@casl/ability';
import {
  ACTION_TYPE,
  ALLOW,
  DENY,
  EDIT,
  PERMISSION_TYPE,
  PERMISSIONS,
  TOOL_NAME_MAP,
  TOOL_PERMISSION_MAP,
  TOOLS_LIST,
  VAST_TRACKER_TABS,
  VIEW,
} from '../constants';
import {
  ACCESS_AD_SERVER,
  ACCESS_CONTENT_PARTNER,
  ACCESS_CONTENT_PARTNER_GROUP,
  ACCESS_DSP,
  ACCESS_SOURCE,
  AD_SERVER,
  AD_UNIT_ID,
  ALL,
  ALL_AD_SERVERS,
  ALL_AD_UNIT_IDS,
  ALL_CONTENT_PARTNERS,
  ALL_DSPS,
  ALL_SOURCES,
  CONTENT_PARTNER,
  CT_FILTER_FIELD,
  CT_TABLE_COLUMN,
  DSP,
  DSP_IDS,
  SOURCE,
  SOURCES,
  TOOL,
} from './constants';

export const defineRules = (userClaim) => {
  const abilities = new Ability();
  const { can, cannot, rules } = new AbilityBuilder(Ability);

  // Vast Tracker
  const allowedVastTrackerTabPermissions = userClaim[PERMISSIONS].filter(
    (permission) => permission[PERMISSION_TYPE] === VAST_TRACKER_TABS && permission[ACTION_TYPE] === ALLOW
  );
  if (allowedVastTrackerTabPermissions) {
    allowedVastTrackerTabPermissions.forEach((tab) => can(VIEW, tab.name));
  }

  // Ad Unit Ids
  const allowedAllAdUnitIds = userClaim[PERMISSIONS].find(
    (permission) =>
      permission['name'] === ALL_AD_UNIT_IDS &&
      permission[PERMISSION_TYPE] === AD_UNIT_ID &&
      permission[ACTION_TYPE] === ALLOW
  );
  if (allowedAllAdUnitIds) {
    can(VIEW, ALL_AD_UNIT_IDS);
  }

  // Content Partners
  const allowedAllContentPartners = userClaim[PERMISSIONS].find(
    (permission) =>
      permission['name'] === ALL_CONTENT_PARTNERS &&
      permission[PERMISSION_TYPE] === CONTENT_PARTNER &&
      permission[ACTION_TYPE] === ALLOW
  );
  const allowedContentPartnerGroups = userClaim['content-partner-groups'].map((group) => group.name);
  const allowedContentPartners = userClaim['content-partners'];
  if (allowedAllContentPartners) {
    can(ACCESS_CONTENT_PARTNER_GROUP, ALL);
    can(ACCESS_CONTENT_PARTNER, ALL);
  } else {
    allowedContentPartnerGroups.forEach((group) => can(ACCESS_CONTENT_PARTNER_GROUP, group));
    allowedContentPartners.forEach((partner) => can(ACCESS_CONTENT_PARTNER, partner.name.toLowerCase()));
  }

  // Ad Servers
  const allowedAllAdServers =
    userClaim[PERMISSIONS].find(
      (permission) =>
        permission['name'] === ALL_AD_SERVERS &&
        permission[PERMISSION_TYPE] === AD_SERVER &&
        permission[ACTION_TYPE] === ALLOW
    ) || [];
  const allowedAdServers =
    userClaim[PERMISSIONS].filter(
      (permission) =>
        permission['name'] !== ALL_AD_SERVERS &&
        permission[PERMISSION_TYPE] === AD_SERVER &&
        permission[ACTION_TYPE] === ALLOW
    ) || [];
  if (allowedAllAdServers.length !== 0) {
    can(ACCESS_AD_SERVER, ALL);
  } else {
    allowedAdServers.forEach((adserver) => can(ACCESS_AD_SERVER, adserver.name.toLowerCase()));
  }

  // DSPs
  const allowedAllDSPS = userClaim[PERMISSIONS].find(
    (permission) =>
      permission['name'] === ALL_DSPS && permission[PERMISSION_TYPE] === DSP && permission[ACTION_TYPE] === ALLOW
  );
  const allowedDSPIds = userClaim[DSP_IDS] || [];
  if (allowedAllDSPS) {
    can(ACCESS_DSP, ALL);
  } else {
    allowedDSPIds.forEach((id) => can(ACCESS_DSP, id));
  }

  // Sources
  const allowedAllSources = userClaim[PERMISSIONS].find(
    (permission) =>
      permission['name'] === ALL_SOURCES && permission[PERMISSION_TYPE] === SOURCE && permission[ACTION_TYPE] === ALLOW
  );

  const allowedSources = userClaim[SOURCES] || [];
  if (allowedAllSources) {
    can(ACCESS_SOURCE, ALL);
  } else {
    allowedSources.forEach((source) => can(ACCESS_SOURCE, source));
  }

  // Creative Tracker
  const allowedCreativeTrackerCols = userClaim[PERMISSIONS].filter(
    (permission) => permission[PERMISSION_TYPE] === CT_TABLE_COLUMN && permission[ACTION_TYPE] === ALLOW
  );
  allowedCreativeTrackerCols.forEach((col) => can(VIEW, col.name));

  const allowedCreativeTrackerFilters = userClaim[PERMISSIONS].filter(
    (permission) => permission[PERMISSION_TYPE] === CT_FILTER_FIELD && permission[ACTION_TYPE] === ALLOW
  );
  allowedCreativeTrackerFilters.forEach((filterField) => can(VIEW, filterField.name));

  // All Tools
  const tools = userClaim[PERMISSIONS].filter((permission) => permission[PERMISSION_TYPE] === TOOL);
  const toolPermissions = userClaim[PERMISSIONS].filter(
    (permission) => permission[PERMISSION_TYPE] === 'TOOL_PERMISSIONS'
  );

  // default deny access
  Object.entries(TOOLS_LIST).forEach((key, value) => {
    cannot(EDIT, key);
    cannot(VIEW, key);
  });

  // edit permissions
  tools.forEach((tool) => {
    const toolName = TOOL_NAME_MAP[tool.name];
    if (toolName && tool[ACTION_TYPE] === ALLOW) {
      can(EDIT, toolName);
      can(VIEW, toolName);
    }
  });

  // view permissions and special case edit permissions
  toolPermissions.forEach((permission) => {
    let tool = TOOL_PERMISSION_MAP[permission.name];
    let type = VIEW;
    if (tool && TOOLS_LIST[tool]?.permissions) {
      // special case edit permission
      if (TOOLS_LIST[tool].permissions.edit?.includes(permission.name)) {
        type = EDIT;
        tool = permission.name;
      }
    }
    if (tool && permission[ACTION_TYPE] === DENY) {
      cannot(type, tool);
    } else if (tool && permission[ACTION_TYPE] === ALLOW) {
      can(type, tool);
    }
  });

  abilities.update(rules);
  return abilities;
};

// TODO: remove
export const isUserContentPartner = (userClaim) => {
  return userClaim?.roles?.some((role) => role.name === 'MP_BASE_ROLE');
};
