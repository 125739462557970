import { CREATIVE_TRACKER_API, GET, PAGINATION_SIZE, POST } from '../constants';
import { jwtTokenHeader, request } from '../utils';

export const getAssetDetails = async (reqBody) =>
  request(
    POST,
    `${CREATIVE_TRACKER_API}/v1/asset-data`,
    reqBody,
    await jwtTokenHeader(),
    'Asset Data load failed'
  ).catch((err) => {
    console.error('Failed to get asset details! ' + err);
  });

export const getCreatives = async (filterSelected) =>
  request(
    POST,
    `${CREATIVE_TRACKER_API}/v1/search`,
    { ...filterSelected, paginationSize: PAGINATION_SIZE },
    await jwtTokenHeader(),
    'Fetching Creatives failed.'
  ).catch((err) => {
    console.error('Failed to get creatives! ' + err);
  });

export const getContentPartners = async () =>
  request(
    GET,
    `/api/content-partner-provider/metadata/content-partners`,
    {},
    await jwtTokenHeader(),
    'Failed to load content partners.'
  ).catch((err) => {
    console.error('Failed to get content partners! ' + err);
  });
