// Permission
export const ACCESS_CONTENT_PARTNER_GROUP = 'ACCESS_CONTENT_PARTNER_GROUP';
export const ACCESS_CONTENT_PARTNER = 'ACCESS_CONTENT_PARTNER';
export const ALL = 'ALL';
export const ALL_AD_UNIT_IDS = 'ALL_AD_UNIT_IDS';
export const AD_UNIT_ID = 'AD_UNIT_ID';
export const ALL_CONTENT_PARTNERS = 'ALL_CONTENT_PARTNERS';
export const CONTENT_PARTNER = 'CONTENT_PARTNER';
export const TOOL = 'TOOL';
export const ALL_AD_SERVERS = 'ALL_AD_SERVERS';
export const AD_SERVER = 'AD_SERVER';
export const ACCESS_AD_SERVER = 'ACCESS_AD_SERVER';
export const ACCESS_DSP = 'ACCESS_DSP';
export const DSP = 'DSP';
export const DSP_IDS = 'dsp-ids';
export const ALL_DSPS = 'ALL_DSPS';
export const ACCESS_SOURCE = 'ACCESS_SOURCE';
export const SOURCE = 'SOURCE';
export const SOURCES = 'sources';
export const ALL_SOURCES = 'ALL_SOURCES';

// Creative Tracker
export const CT_FILTER_FIELD = 'CT_FILTER_FIELD';
export const CT_TABLE_COLUMN = 'CT_TABLE_COLUMN';
