import React from 'react';
import styled from 'styled-components';
import { Alert, Skeleton } from 'antd';
import { Button } from '@hulu/react-style-components';
import isEmpty from 'lodash/isEmpty';

import { AuctionErrorBoundary } from '../AuctionErrorBoundary';
import { formatDate } from '../../../utils';
import { TIME_FRAMES, DURATION_OPTIONS } from '../../../constants';
import { Card, StyledCardItem } from './Card';
import { StyledDrawer, StyledCloseButton } from './style';

const StyledCardItemTitle = styled.div`
  min-width: 210px;
`;
export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  .action-rerun {
    box-sizing: border-box;
    box-shadow: 4px 0 16px rgba(0, 0, 0, 0.08);
    height: 72px;
    padding: 13px 16px 13px 0;
    text-align: end;
    > a {
      margin: 0;
    }
  }
  .ant-skeleton-title {
    margin-bottom: 0;
    margin-top: 8px;
  }
  .ant-skeleton-paragraph {
    margin-bottom: 8px;
    margin-top: 0;
  }
  .ant-skeleton-content {
    padding: 0 8px;
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 8px;
  }
  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 8px;
  }
`;

const sanitizeEmptyValue = (obj = {}) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => !isEmpty(v)));

const CardItem = ({ title, data }) =>
  data ? (
    <StyledCardItem>
      <StyledCardItemTitle>{title}</StyledCardItemTitle>
      <span>{data}</span>
    </StyledCardItem>
  ) : (
    <></>
  );

export const SnapshotParametersInfo = ({
  isOpen,
  onClose,
  data,
  isLoading,
  error,
  snapshotId,
  onRerunSnapshot,
  profileTypes,
}) => {
  const { supplyParameters, demandParameters, name, status, date, timeAllowedBeforeStopRunning } = data;
  const {
    supplySource,
    appName,
    channel,
    deviceFamily,
    inventoryType,
    ifaType,
    vdm,
    sessionId,
    selectionSearch,
    domain,
    dnt,
    userAgent,
    series,
    assetId,
    contentPartner,
    pod,
    brand,
    deviceCategory,
    distributor,
    formFactor,
    country,
    supplyAdUnitId,
  } = supplyParameters || {};
  const {
    result,
    advertiser,
    creativeDuration,
    creativeId,
    mediaFileUrl,
    winningAuction,
    adPartnerDsp,
    receivedBidDealId,
    bidState,
    externalDealId,
    internalDealId,
  } = demandParameters || {};
  const demandParametersCardData = sanitizeEmptyValue({
    creativeDuration,
    mediaFileUrl,
    winningAuction,
    adPartnerDsp,
    receivedBidDealId,
    bidState,
    externalDealId,
    internalDealId,
  });
  const appParametersCardData = sanitizeEmptyValue({
    inventoryType,
    deviceFamily,
    ifaType,
    domain,
    userAgent,
    dnt,
    brand,
    formFactor,
    deviceCategory,
  });
  const contentParametersCardData = sanitizeEmptyValue({
    channel,
    series,
    assetId,
    contentPartner,
    vdm,
    sessionId,
    pod,
    distributor,
    supplyAdUnitId,
  });

  const sanitizedWinningAuction = demandParametersCardData?.winningAuction;
  const winningAuctionToRender =
    sanitizedWinningAuction?.length > 1 ? sanitizedWinningAuction.join(', ') : sanitizedWinningAuction;

  return (
    <StyledDrawer
      placement="right"
      open={isOpen}
      onClose={onClose}
      destroyOnClose
      title={
        <span>
          Parameters applied: <span className="drawer-title-text">{isLoading ? 'loading...' : name}</span>
        </span>
      }
      width={600}
      closeIcon={<StyledCloseButton variant="outlined" icon="IconClose" />}
    >
      <AuctionErrorBoundary>
        {error ? (
          <Alert type="error" message={error} />
        ) : (
          <StyledContent>
            <div>
              <Skeleton paragraph={{ rows: 28 }} loading={isLoading}>
                <Card title="Snapshot Parameters">
                  <CardItem title="Supply Source" data={supplySource} />
                  <CardItem title="Publisher" data={appName} />
                  <CardItem title="Country" data={country} />
                  <CardItem title="Advertiser" data={advertiser} />
                  <CardItem title="DSP : CRID" data={creativeId} />
                  <CardItem
                    title="Selected Type"
                    data={profileTypes?.find(({ value }) => value === selectionSearch?.selectType)?.label}
                  />
                  <CardItem title="Device ID/Profile ID" data={selectionSearch?.inputValue} />
                  {!isEmpty(contentParametersCardData) && (
                    <>
                      <CardItem title="Session ID" data={sessionId} />
                    </>
                  )}
                  <CardItem title="Result" data={result} />
                  <CardItem title="Status" data={status} />
                  <CardItem title="Date" data={formatDate(date)} />
                  <CardItem
                    title="Time Allowed Before Stop Running"
                    data={TIME_FRAMES.find((item) => item.value === timeAllowedBeforeStopRunning)?.label}
                  />
                </Card>
                {!isEmpty(appParametersCardData) && (
                  <Card title="App Parameters">
                    <CardItem title="Inventory Type" data={inventoryType} />
                    <CardItem title="Brand" data={brand} />
                    <CardItem title="Form-Factor" data={formFactor} />
                    <CardItem title="Device Category" data={deviceCategory} />
                    <CardItem title="Device Name" data={deviceFamily} />
                    <CardItem title="IFA Type" data={ifaType} />
                    <CardItem title="Domain" data={domain} />
                    <CardItem title="User Agent" data={userAgent} />
                    <CardItem title="DNT" data={dnt} />
                  </Card>
                )}
                {!isEmpty(contentParametersCardData) && (
                  <Card title="Content Parameters">
                    <CardItem title="Distributor" data={distributor} />
                    <CardItem title="Series" data={series} />
                    <CardItem title="Asset ID" data={assetId} />
                    <CardItem title="Supply Ad Unit ID (UUID)" data={supplyAdUnitId} />
                    <CardItem title="Content Partner" data={contentPartner} />
                    <CardItem title="Channel" data={channel} />
                    <CardItem title="VDM" data={vdm} />
                    <CardItem title="Pod" data={pod} />
                  </Card>
                )}
                {!isEmpty(demandParametersCardData) && (
                  <Card title="Demand Parameters">
                    <CardItem
                      title="Creative Duration"
                      data={
                        creativeDuration &&
                        DURATION_OPTIONS.filter((item) => creativeDuration.includes(item.value)).map((item, index) => {
                          return (index ? ' ' : '') + item.label;
                        }) + ' seconds'
                      }
                    />
                    <CardItem title="Deal ID (External)" data={externalDealId} />
                    <CardItem title="System Deal ID (Internal)" data={internalDealId} />
                    <CardItem title="Bid State" data={bidState} />
                    <CardItem title="Media File Url" data={mediaFileUrl} />
                    <CardItem title="Winning Demand Partner" data={winningAuctionToRender} />
                    <CardItem title="DSP (Magnite)" data={adPartnerDsp} />
                    <CardItem title="Received Bid Deal Type" data={receivedBidDealId} />
                  </Card>
                )}
              </Skeleton>
            </div>
            <div className="action-rerun">
              <Button
                icon="IconCopy"
                size="medium"
                variant="outlined"
                onClick={() => {
                  onRerunSnapshot({ id: snapshotId });
                  onClose();
                }}
              >
                Rerun Snapshot
              </Button>
            </div>
          </StyledContent>
        )}
      </AuctionErrorBoundary>
    </StyledDrawer>
  );
};
