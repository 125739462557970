import { useState, useEffect } from 'react';
import { getDealDetailsById } from '../../../../services/auctionSnapshot';
import { AMAZON, MAGNITE, ROKU, RESPONSE, CAS, VAST, DV360, MELI, TTD } from '../constants';

const DEFAULT_BID_DETAILS_DATA = {
  bidWithVastData: null,
  bidResponse: null,
  adResponse: null,
  isAdResponseJson: false,
};

const getMagniteXMLByAdId = (xml, adId) => {
  try {
    const parser = new DOMParser();
    const serializer = new XMLSerializer();

    const doc = parser.parseFromString(xml, 'application/xml');
    const targetNode = doc.getElementById(adId);
    const targetXmlToString = serializer.serializeToString(targetNode);
    return targetXmlToString;
  } catch (error) {
    console.error(`getMagniteXMLByAdId ${error}`);
    throw new Error('Incorrect Magnite XML!');
  }
};

const getBidDetailsFromXML = (xml) => {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(xml, 'application/xml');
    const adId = doc?.getElementsByTagName('Ad')[0]?.getAttribute('id');
    const adSystem = doc?.getElementsByTagName('AdSystem')[0]?.textContent.replace(/(\n|^\s+)/gm, '');
    const adTitle = doc?.getElementsByTagName('AdTitle')[0]?.textContent.replace(/(\n|^\s+)/gm, '');
    return { adId, adSystem, adTitle };
  } catch (error) {
    console.error(`getBidDetailsFromXML ${error}`);
    throw new Error("Can't get bid details from XML!");
  }
};

const getBidResponseMultiRequest = (data, demandBidId, cridId) => {
  return (
    data
      ?.reduce((acc, { record }) => {
        const seatbids = record?.rawData?.seatbid || [];
        seatbids.forEach(({ bid }) => acc.push(...(bid || [])));
        return acc;
      }, [])
      .find(({ id, crid }) => id === demandBidId && (!cridId || crid === cridId)) || null
  );
};

const magniteBidDetails = ({ currentBid, integratorsData }) => {
  const bidWithVastData = {
    ...currentBid,
    ...getBidDetailsFromXML(
      getMagniteXMLByAdId(integratorsData?.[MAGNITE]?.[RESPONSE]?.record?.rawData, currentBid?.demandBidId)
    ),
  };
  const adResponse = getMagniteXMLByAdId(integratorsData?.[MAGNITE]?.[RESPONSE]?.record?.rawData, bidWithVastData.adId);
  return { bidWithVastData, adResponse };
};

const rokuBidDetails = ({ currentBid, integratorsData, auctionSupplySource }) => {
  const { adm, ...rest } =
    integratorsData?.[ROKU]?.[RESPONSE]?.record?.rawData?.seatbid[0]?.bid?.find(
      (bid) => bid?.id === currentBid?.demandBidId
    ) || {};

  let bidWithVastData = null;
  let isAdResponseJson = false;

  const vastRawData = integratorsData?.[ROKU]?.[VAST]?.record?.rawData;

  if (auctionSupplySource === CAS) {
    bidWithVastData = {
      ...currentBid,
      adId: vastRawData?.ad?.id,
      adSystem: vastRawData?.ad?.['ad-system'],
      adTitle: vastRawData?.ad?.name,
    };
    isAdResponseJson = true;
  } else {
    bidWithVastData = {
      ...currentBid,
      ...getBidDetailsFromXML(vastRawData),
    };
  }
  return { bidWithVastData, bidResponse: rest, adResponse: vastRawData, isAdResponseJson };
};

const amazonBidDetails = ({ currentBid, integratorsData }) => {
  const vastRawData = integratorsData?.[AMAZON]?.[VAST]?.find(({ record }) => record?.rawData?.id === currentBid?.bidId)
    ?.record?.rawData;

  return {
    bidWithVastData: {
      ...currentBid,
      adId: vastRawData?.ad?.id,
      adSystem: vastRawData?.ad?.['ad-system'],
      adTitle: vastRawData?.ad?.name,
    },
    bidResponse: integratorsData?.[AMAZON]?.[RESPONSE]?.record?.rawData?.seatbid?.[0]?.bid?.find(
      (bid) => bid?.cridid === currentBid?.cridid
    ),
    adResponse: vastRawData?.ad,
    isAdResponseJson: true,
  };
};

const dv360BidDetails = ({ currentBid, integratorsData }) => {
  const { demandBidId, bidId } = currentBid;
  const vastRawData = integratorsData?.[DV360]?.[VAST]?.find(({ record }) => record?.rawData?.id === bidId)?.record
    ?.rawData;

  return {
    bidWithVastData: {
      ...currentBid,
      adId: vastRawData?.ad?.id,
      adSystem: vastRawData?.ad?.['ad-system'],
      adTitle: vastRawData?.ad?.name,
    },
    bidResponse: getBidResponseMultiRequest(integratorsData?.[DV360]?.[RESPONSE], demandBidId),
    adResponse: vastRawData,
    isAdResponseJson: true,
  };
};

const meliBidDetails = ({ currentBid, integratorsData }) => {
  const { adm, ...rest } =
    integratorsData?.[MELI]?.[RESPONSE]?.record?.rawData?.seatbid[0]?.bid?.find(
      (bid) => bid?.id === currentBid?.demandBidId
    ) || {};

  const vastRawData = integratorsData?.[MELI]?.[VAST]?.record?.rawData;

  return {
    bidWithVastData: {
      ...currentBid,
      adId: vastRawData?.ad?.id,
      adSystem: vastRawData?.ad?.['ad-system'],
      adTitle: vastRawData?.ad?.name,
    },
    bidResponse: rest,
    adResponse: vastRawData,
    isAdResponseJson: true,
  };
};

const ttdBidDetails = ({ currentBid, integratorsData }) => {
  const { demandBidId, cridId, bidId } = currentBid;
  const vastRawData = integratorsData?.[TTD]?.[VAST]?.find(({ record }) => record?.rawData?.id === bidId)?.record
    ?.rawData;

  return {
    bidWithVastData: {
      ...currentBid,
      adId: vastRawData?.ad?.id,
      adSystem: vastRawData?.ad?.['ad-system'],
      adTitle: vastRawData?.ad?.name,
    },
    bidResponse: getBidResponseMultiRequest(integratorsData?.[TTD]?.[RESPONSE], demandBidId, cridId),
    adResponse: vastRawData,
    isAdResponseJson: true,
  };
};

const bidDetailsMap = {
  [MAGNITE]: magniteBidDetails,
  [ROKU]: rokuBidDetails,
  [AMAZON]: amazonBidDetails,
  [DV360]: dv360BidDetails,
  [MELI]: meliBidDetails,
  [TTD]: ttdBidDetails,
};

const resolveBidDetails = (bidIntegrator, data) => {
  const fn = bidDetailsMap[bidIntegrator];
  return fn ? fn(data) : DEFAULT_BID_DETAILS_DATA;
};

export const useBidDetails = (integratorsData, auctionSupplySource) => {
  const [isOpenBidDetails, setIsOpenBidDetails] = useState(false);
  const [isBidDetailsLoading, setIsBidDetailsLoading] = useState(false);
  const [currentBid, setCurrentBid] = useState(null);

  const [bidDetailsData, setBidDetailsData] = useState(DEFAULT_BID_DETAILS_DATA);

  const [isDealDetailsLoading, setIsDealDetailsLoading] = useState(false);
  const [dealDetailsError, setIsDealDetailsError] = useState(null);

  const openBidDetails = (bid) => {
    setIsOpenBidDetails(true);
    setIsBidDetailsLoading(true);
    setCurrentBid(bid);
  };
  const closeBidDetails = () => {
    setIsOpenBidDetails(false);
    setBidDetailsData(DEFAULT_BID_DETAILS_DATA);
    setCurrentBid(null);
  };

  useEffect(() => {
    const bidIntegrator = currentBid?.demandPartner?.toUpperCase();

    setBidDetailsData((prevState) => ({
      ...prevState,
      ...resolveBidDetails(bidIntegrator, { currentBid, integratorsData, auctionSupplySource }),
    }));

    if (currentBid?.dealId) {
      setIsDealDetailsLoading(true);
      getDealDetailsById(currentBid?.dealId)
        .then((dealDetails) => {
          setBidDetailsData((prevState) => ({ ...prevState, dealDetails }));
        })
        .catch(setIsDealDetailsError)
        .finally(() => setIsDealDetailsLoading(false));
    }

    setIsBidDetailsLoading(false);
  }, [currentBid, integratorsData, auctionSupplySource]);

  return {
    isOpenBidDetails,
    bidDetailsData,
    isBidDetailsLoading: isBidDetailsLoading || isDealDetailsLoading,
    dealDetailsError,
    openBidDetails,
    closeBidDetails,
  };
};
