import {
  CONTENT_PARTNER_ID,
  CONTENT_PARTNER_NAME,
  CP_IDENTIFIER,
  HISTORY_ACTION,
  NETWORK_NAME,
  SOURCE_ID,
  TRIGGER_TYPE,
  UPDATED_AT,
  UPDATED_BY,
  UPID_LOOKUP_STRATEGY,
} from '../../pages/ConfigManager/utils';
import { STATUS } from '../../constants';

export const formatCpOptions = (rawCp) => {
  return Object.entries(rawCp).map(([id, obj]) => {
    return {
      label: obj.name,
      value: obj.id,
    };
  });
};

export const formatAuditHistory = (history) => {
  return Object.entries(history).map(([id, obj]) => {
    return {
      key: obj[SOURCE_ID] + ':' + obj.snapshot[UPDATED_AT],
      [`${CONTENT_PARTNER_ID}`]: obj.snapshot[CONTENT_PARTNER_ID],
      [`${CONTENT_PARTNER_NAME}`]: obj.snapshot[CP_IDENTIFIER],
      [`${SOURCE_ID}`]: obj[SOURCE_ID],
      [`${TRIGGER_TYPE}`]: obj.snapshot[TRIGGER_TYPE],
      [`${UPID_LOOKUP_STRATEGY}`]: obj.snapshot[UPID_LOOKUP_STRATEGY]?.name,
      [`${NETWORK_NAME}`]: obj.snapshot[UPID_LOOKUP_STRATEGY]?.[NETWORK_NAME] ?? 'N/A',
      [`${STATUS}`]: obj.snapshot[STATUS],
      [`${HISTORY_ACTION}`]: obj.action,
      [`${UPDATED_BY}`]: obj.snapshot[UPDATED_BY],
      [`${UPDATED_AT}`]: obj.snapshot[UPDATED_AT],
    };
  });
};
