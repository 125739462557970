import { connect } from 'dva';
import { Button, Form, Input, Select } from 'antd';
import { FORM_NOT_EMPTY_RULE, FORM_UUID_RULE } from '../../../utils';
import { Link, routerRedux } from 'dva/router';
import { BAD_REQUEST, CONFIG_MANAGER_PATH, CONFIG_MANAGER_SOURCE_DETAILS_PATH_PREFIX } from '../../../constants';
import { Typography } from '@hulu/react-style-components';
import { DivWrapper } from './styles';
import {
  CONTENT_PARTNER_ID,
  CP_IDENTIFIER,
  NETWORK_NAME,
  SOURCE_ID,
  TRIGGER_TYPE,
  UPID_LOOKUP_STRATEGY,
} from '../utils';
import { DISPLAY_STYLE_BLOCK, DISPLAY_STYLE_NONE } from '../../DurationManager/constants';
import { useState } from 'react';
import { NETWORK_OPTIONS, TRIGGER_OPTIONS, UPID_OPTIONS } from '../constants';

const CreateSourceDetails = ({
  dispatch,
  currentUser,
  configData = [],
  contentPartnerOptions = [],
  pathParams = {},
}) => {
  const [form] = Form.useForm();
  const [sourceExists, setSourceExists] = useState(false);

  const onFinish = async (value) => {
    const formattedSource = {
      [`${CONTENT_PARTNER_ID}`]: value['cp-name'].key,
      [`${CP_IDENTIFIER}`]: value['cp-name'].label,
      [`${SOURCE_ID}`]: value[SOURCE_ID],
      [`${TRIGGER_TYPE}`]: value[TRIGGER_TYPE],
      [`${UPID_LOOKUP_STRATEGY}`]: {
        name: value[UPID_LOOKUP_STRATEGY],
        [`${NETWORK_NAME}`]: value.network,
      },
    };

    const { statusCode } = await dispatch({
      type: 'configManager/createSource',
      payload: { reqBody: formattedSource },
    });
    if (statusCode === BAD_REQUEST) {
      setSourceExists(true);
    } else {
      dispatch(routerRedux.push(`${CONFIG_MANAGER_PATH}`, pathParams));
    }
  };

  const onSourceDetailsPageClick = () => {
    dispatch(
      routerRedux.push(
        `${CONFIG_MANAGER_SOURCE_DETAILS_PATH_PREFIX}/source-id/${form.getFieldValue(SOURCE_ID)}`,
        pathParams
      )
    );
  };

  return (
    <DivWrapper>
      <Link to={CONFIG_MANAGER_PATH}>
        <Typography variant={'h6'}>&lt; Back to Partner DAI Config Manager</Typography>
      </Link>
      <br />
      <h1>Create New Source</h1>
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="cp-name" label="Content Partner Name" rules={[{ required: true }]}>
          <Select
            showSearch
            allowClear
            labelInValue
            options={contentPartnerOptions}
            placeholder={'Choose Content Partner Name'}
            filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          />
        </Form.Item>
        <Form.Item
          name={SOURCE_ID}
          label="Source Id"
          rules={[{ required: true, message: 'Please input source id value' }, FORM_NOT_EMPTY_RULE, FORM_UUID_RULE]}
          validateTrigger="onSubmit"
        >
          <Input placeholder="Source ID" />
        </Form.Item>
        <Form.Item name={TRIGGER_TYPE} label="Trigger Type" rules={[{ required: true }]}>
          <Select placeholder="Choose Trigger Type" allowClear options={TRIGGER_OPTIONS} />
        </Form.Item>
        <Form.Item name={UPID_LOOKUP_STRATEGY} label="UPID Lookup Strategy" rules={[{ required: true }]}>
          <Select placeholder="Choose UPID Lookup Strategy" allowClear options={UPID_OPTIONS} />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues[UPID_LOOKUP_STRATEGY] !== currentValues[UPID_LOOKUP_STRATEGY]
          }
        >
          {({ getFieldValue }) =>
            getFieldValue(UPID_LOOKUP_STRATEGY) === 'EspnPccLookUpStrategy' ? (
              <Form.Item name="network" label="Network" rules={[{ required: true }]}>
                <Select placeholder="Choose a Network" allowClear options={NETWORK_OPTIONS} />
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Form.Item>
          <h3 style={{ display: sourceExists ? DISPLAY_STYLE_BLOCK : DISPLAY_STYLE_NONE }}>
            A configuration with this source id already exists! Go to the{' '}
            <Link onClick={onSourceDetailsPageClick}> Source Details </Link>
            page to edit it instead.
          </h3>
        </Form.Item>
      </Form>
    </DivWrapper>
  );
};

function mapStateToProps({ app, configManager, loading }) {
  return {
    currentUser: app.currentUser,
    configData: configManager.configManagerState.configData,
    contentPartnerOptions: configManager.configManagerState.contentPartnerOptions,
    pathParams: app.pathParams,
    loading: loading.effects['configManager/fetchConfigData'],
  };
}

export default connect(mapStateToProps)(CreateSourceDetails);
