import React from 'react';
import { StyledCard } from '../style';
import { RowInfo } from './RowInfo';
import { DESCRIPTION_CARD } from '../../../../testUtils';

export const DescriptionCard = ({ title, isLoading, data }) => {
  return (
    <StyledCard title={title} loading={isLoading} data-testid={DESCRIPTION_CARD}>
      {data ? (
        Object.entries(data).map(([key, value]) => <RowInfo key={key} title={key} data={value} />)
      ) : (
        <div>No Data</div>
      )}
    </StyledCard>
  );
};
